@import '~antd/dist/antd.css';

body {
    overflow-x: initial;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.65);
}

.page,
.landing > p {
    padding: 15px;
    width: 980px;
    margin: auto auto;
}

.landing a, .landing a:hover, .landing a:visited,
.page a, .page a:hover, .page a:visited {        
    color: #79670b;                              
    text-decoration-line: underline;             
}                      

.page img,
.landing img {
    width: 100%;
}

.page .logo {
    max-width: 100%;
    width: auto;
    height: auto;
}

.page table,
.page .video,
.page .vimeo-iframe-container {
    margin-bottom: 25px;
}

#healcode-container {
    display: none;
    padding: 15px;
    width: 980px;
    margin: auto auto;
}

div.healcode.registration .hc-registration-field>.liability {
    height: 100% !important;
}

div.healcode.registration .hc-registration-field>input {
    width: unset !important;
}

.header, .header-mobile {
    padding: 15px;
    max-width: 980px;
    margin: auto auto;
}

.header-mobile {
    width: 100%;
    text-align: center;
}

.header .ant-menu-horizontal,
.header .ant-menu-vertical {
    border-bottom: none;
    font-size: 1rem;
}

.home-img {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.25) !important;
    background: none;
    border-color: transparent !important;
    padding: 0px;
}


.page table > tbody > tr > td {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: rgba(0, 0, 0, 0.65);
}
.page table > tbody > tr:first-child > td:first-child {
    border-radius: 6px 0px 0px;
}
.page table > tbody > tr:first-child > td:last-child {
    border-radius: 0px 6px 0px 0px;
}
.page table > tbody > tr:last-child > td:first-child {
    border-radius: 0px 0px 0px 6px;
}
.page table > tbody > tr:last-child > td:last-child {
    border-radius: 0px 0px 6px 0px;
}
.page table > tbody > tr > td:first-child {
    background-color: #e6e6e6;
    text-align: right;
    font-weight: bold;
}
.page table > tbody > tr > td:last-child {
    background-color: #f5f5f5;
}

.landing table {
  border-top-width: 0;
  border-color: #fff;
}

.landing table > tbody > tr > td > h2 {
    color: rgba(0, 0, 0, 0.8);
}
.landing table > tbody > tr > td {
    padding-left: 0px;
    padding-right: 0px;
    background-color: #fea;
    /*background-color: #ffef98;*/
    border-radius: 0px 0px 0px 0px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.8);
}
.landing table > tbody > tr > td > img {
    overflow: hidden;
    object-fit: cover;
}

.landing table > tbody > tr:nth-child(odd) > td:first-child,
.landing table > tbody > tr:nth-child(even) > td:last-child
{
    padding: 30px;
}

@media (max-width: 1000px) {
    .landing table > tbody > tr:nth-child(even) > td:last-child
    {
        padding: 0px;
    }
}  

@media (max-width: 980px) {
    .page, .landing > p, #healcode-container {                  
        width: 100%;           
        padding: 15px;         
        margin: unset;         
    }
}  

@media (max-width: 445px) {
    .pricing-table > table {
        width: 100%;           
        margin: unset;         
        margin-bottom: 25px;
    }
}  

.pricing-table {
    font-size: 1rem;
    margin-bottom: 25px;
}

.pricing-table thead {
    word-break: break-all;
}

.pricing-table .tooltip {
    cursor: pointer;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.65);
}

.pricing-table table > tbody > tr > td {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: rgba(0, 0, 0, 0.65);
}
.pricing-table table > tbody > tr > td {
    border-radius: 0px 0px 0px 0px !important;
}
.pricing-table table > thead > tr:first-child > th:first-child {
    border-radius: 6px 0px 0px;
}
.pricing-table table > thead > tr:first-child > th:last-child {
    border-radius: 0px 6px 0px 0px;
}
.pricing-table table > tbody > tr:last-child > td:first-child {
    border-radius: 0px 0px 0px 6px !important;
}
.pricing-table table > tbody > tr:last-child > td:last-child {
    border-radius: 0px 0px 6px 0px !important;
}
.pricing-table table > thead > tr > th {
    background-color: #e6e6e6;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
    font-weight: bold;
}
.pricing-table table > tbody > tr > td {
    background-color: #f5f5f5 !important;
}


iframe {
    display: block;       /* iframes are inline by default */
    border: none;         /* Reset default border */
    width: 100%;
    height: 700px;
}

.vimeo-iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.vimeo-iframe-container iframe {
   border: 0;
   height: 100% !important;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}

.ant-menu-submenu-title,
.ant-menu-item > a {
    font-size: 1rem !important;
}

.ant-menu-inline {
    border-right: none;
}

.lvcc-quick-contact {
    font-size: 1rem;
    padding: 15px;
    display: flex;
    flex-direction: column;
    float: right;
    top: 30px;
}

.lvcc-phone {
    float: right;
    top: 30px;
}

.lvcc-email {
    float: right;
    top: 45px;
}

.lvcc-logo {
    display: inline-block;
    width: 180px;
    left: 15px;
    top: 15px;
    margin: auto auto;
}

.lvcc-logo-mobile {
    display: inline-block;
    width: 180px;
    left: 15px;
    top: 15px;
    margin: auto auto;
}


.ant-layout-footer {
    background-color: #fc0;
    box-shadow: 0px 0 5px rgba(0, 0, 0, 0.8);
    padding: 0;

}

.lvcc-footer {
    max-width: 980px;
    margin: auto auto;
    padding: 40px 15px;
}

.lvcc-footer a,
.lvcc-footer a:hover {
    color: rgba(0, 0, 0, 0.65);
    border-bottom: 1px dashed rgba(0, 0, 0, 0.65);
}

.lvcc-footer > .lvcc-footer-about-us,
.lvcc-footer > .lvcc-footer-important-links {
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

.lvcc-footer-important-links > ul {
    padding-left: 15px;
}

.lvcc-footer-logo {
    width: 180px;
}

.lvcc-mobile-hamburger, .lvcc-mobile-hamburger:hover {
    font-size: 18px;
    font-weight: bolder;
    height: 50px;
    position: fixed; 
    bottom: 20px !important;
    right: 20px !important;
    z-index: 999;
    background-color: #fc0;
    color: #000;
    border: 0;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important;
}

.lvcc-mobile-hamburger > span, .lvcc-mobile-hamburger:hover > span {
    color: #333;
}

.copyright {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 15px 0;
}

/* Jobs page */
.jobs {
    height: 2744px;
    text-align: center;
}

.jobs iframe {
    height: 2624px;
    width: 100%;
}

.jobs .spin {
    padding-top: 100px;
}

/* Privates page */
.privates {
    height: 1744px;
    text-align: center;
}

.privates iframe {
    height: 1724px;
    width: 100%;
}

.privates .spin {
    padding-top: 100px;
}

/* Signup page */
.signup {
    height: 1244px;
    text-align: center;
}

.signup iframe {
    height: 1224px;
    width: 100%;
}

.signup .spin {
    padding-top: 100px;
}

/* GoFundMe page */
.gofundme {
    height: 228px;
    width: 478px;
    text-align: center;
    float: right;
}

.gofundme iframe {
    height: 228px;
}

.gofundme .spin {
    padding-top: 100px;
}

@media (max-width: 478px) {
    .gofundme {
        width: 400px;
    }
}  


/* Birthdays page */
.birthdays {
    height: 1744px;
    text-align: center;
}

.birthdays iframe {
    height: 1724px;
    width: 100%;
}

.birthdays .spin {
    padding-top: 100px;
}

/* Scholarship page */
.scholarship {
    height: 2444px;
    text-align: center;
}

.scholarship iframe {
    height: 2424px;
    width: 100%;
}

.scholarship .spin {
    padding-top: 100px;
}


/* Group Events page */
.groupevents {
    height: 1744px;
    text-align: center;
}

.groupevents iframe {
    height: 1724px;
    width: 100%;
}

.groupevents .spin {
    padding-top: 100px;
}


.app-store-download-image {
    width: auto !important;
}


.bw-empty__subtext, .bw-widget__cta.js-locked {
    display: none !important;
}


/* Voting related styles */
.candidate-card {
    width: 300px; 
    background-color: transparent;
    /* border-color: #baf3b9; */
}

.candidate-card.selected {
    background-color: #baf3b9;
    border-color: #baf3b9;
}

.candidate-card h2 {
    color: #707070;
}

.candidate-card .avatar {
    margin-bottom: 20px;
}

.candidate-card .group-avatar {
    margin-bottom: 20px;
    margin-left: -22px;
}

.vote-board {
    margin-top: 30px !important;
    margin-bottom: 50px !important;
}

.vote-board.loading {
    margin-top: 100px !important;
}

.vote-board.loading > .ant-spin {
    margin: auto auto;
    width: 100%;
}

.vote-button-container {
    margin-top: 20px;
    text-align: right;
    padding-right: 3px;
}

.vote-button-container > button,
.vote-button-container > button:focus {
    color: rgba(0, 0, 0, 0.85);
    background-color: #ffcc00;
    border-color: darkgrey;
}

.vote-button-container > button:hover {
    color: rgba(0, 0, 0, 0.85);
    background-color: #ffeca0;
    border-color: darkgrey;
}

.right {
  text-align: right;
}

.tally-board {
    display: flex;
    justify-content: center; 
    flex-wrap: wrap;
}

/* .ag-header-cell-text { */
/*     text-align: center; */
/*     width: 100%; */
/* } */

.ag-row .ag-cell {
  display: flex;
  justify-content: flex-start;; /* align horizontal */
  align-items: center;
}

.ag-row .ag-cell.center {
  display: flex;
  justify-content: center;; /* align horizontal */
  align-items: center;
}

.ag-row .ag-cell.right {
  display: flex;
  justify-content: flex-end; /* align horizontal */
  align-items: center;
}

.ag-icon-desc, .ag-icon-asc,
.ag-header-cell-label .ag-header-icon.ag-sort-order {
  display: none;
}

.ag-header-cell-label {
   justify-content: center;
}
